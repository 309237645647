import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import ui from "./modules/ui";
import user from "./modules/user";
import map from "./modules/map";
import dialogMap from "./modules/dialog-map";
import data from "./modules/data";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    ui,
    user,
    map,
    data,
    dialogMap,
  },
  getters,
});

export default store;
