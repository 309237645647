export const crop_properties = [
    {
        id: "ci_green",
        name: "Chlorophyll Content",
    },
    {
        id: "evi",
        name: "Canopy Structured Variable",
    },
    {
        id: "ndvi",
        name: "Crop Condition",
    },
    {
        id: "psri",
        name: "Crop Age",
    },
    {
        id: "savi",
        name: "Canopy Structure",
    },
];
export const crop_property_ranges = {
    ndvi: [
        {
            name: "Stressed",
            min: "0",
            color: "FF0000",
        },
        {
            name: "Slightly Stressed",
            min: "0.2",
            color: "ED7D31",
        },
        {
            name: "Moderate",
            min: "0.4",
            color: "FFFF00",
        },
        {
            name: "Healthy",
            min: "0.4",
            color: "92D050",
        },
        {
            name: "Very Healthy",
            min: "0.6",
            color: "375623",
        },
    ],
    ci_green: [
        {
            name: "Very Low",
            min: "0",
            color: "FF0000",
        },
        {
            name: "Low",
            min: "1",
            color: "ED7D31",
        },
        {
            name: "Normal",
            min: "1.5",
            color: "FFFF00",
        },
        {
            name: "High",
            min: "2",
            color: "92D050",
        },
        {
            name: "Very High",
            min: "2.5",
            color: "375623",
        },
    ],
    psri: [
        {
            name: "Very Early",
            min: "-1",
            color: "ED7D31",
        },
        {
            name: "Early",
            min: "-1",
            color: "FFFF00",
        },
        {
            name: "Timely",
            min: "0.1",
            color: "92D050",
        },
        {
            name: "Slightly Late",
            min: "0.12",
            color: "375623",
        },
    ],
    savi: [
        {
            name: "Open",
            min: "0",
            color: "CC0000",
        },
        {
            name: "Low Dense",
            min: "0.2",
            color: "FF0000",
        },
        {
            name: "Slightly Dense",
            min: "0.4",
            color: "ED7D31",
        },
        {
            name: "Dense",
            min: "0.6",
            color: "FFFF00",
        },
        {
            name: "Highly Dense",
            min: "0.8",
            color: "92D050",
        },
        {
            name: "Very High",
            min: "0.8",
            color: "375623",
        },
    ],
    evi: [
        {
            name: "Extremely Low",
            min: "0",
            color: "CC0000",
        },
        {
            name: "Very Low",
            min: "0",
            color: "FF0000",
        },
        {
            name: "Low",
            min: "0.2",
            color: "ED7D31",
        },
        {
            name: "Normal",
            min: "0.5",
            color: "FFFF00",
        },
        {
            name: "High",
            min: "0.8",
            color: "92D050",
        },
        {
            name: "Very High",
            min: "1.1",
            color: "375623",
        },
    ],
    sipi: [
        {
            name: "Stressed",
            min: "0",
            color: "FFFF00",
        },
        {
            name: "Healthy",
            min: "0",
            color: "92D050",
        },
        {
            name: "Very Healthy",
            min: "0",
            color: "375623",
        },
    ],
};
