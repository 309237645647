<template>
  <v-app>
    <AppBar />
    <Sidebar />
    <Main />
    <Snackbar />
    <Loader />
    <FarmerDetailDialog />
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import Loader from "@/components/Loader";
import AppBar from "./components/AppBar";
import Sidebar from "./components/sidebar/Sidebar";
import Main from "./components/main/Main";
import FarmerDetailDialog from "./components/dialog/FarmerDetailDialog";

export default {
  props: ["searchData"],
  components: {
    AppBar,
    Snackbar,
    Loader,
    Sidebar,
    Main,
    FarmerDetailDialog,
  },
  data() {
    return {
      afterSavingSearch: false,
    };
  },
  mounted() {
    if (this.searchData) {
      if (this.searchData === "error") {
        this.$snackbar("Search uuid was not found", "error");
        return;
      } else
        this.$nextTick(() =>
          this.$store.dispatch("map/draw/loadSavedSearch", this.searchData)
        );
    }
  },
};
</script>
