<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card style="background: #1e1e1e">
      <v-toolbar dense color="primary" dark flat>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Station Analysis</v-toolbar-title>

        <template v-slot:extension>
          <v-tabs
            v-model="tab"
            color="primary"
            align-with-title
            background-color="grey darken-3"
          >
            <v-tab>Daily water balance</v-tab>
            <v-tab>Seasonal water balance</v-tab>
            <v-tab>meteogram</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-text v-if="dialog" class="py-10 px-8">
        <DailyWaterBalance
          v-if="tab == 0"
          :start-date="startDate"
          :end-date="endDate"
        />
        <SeasonalWaterBalance
          v-if="tab == 1"
          :start-date="startDate"
          :end-date="endDate"
        />
        <Meteogram
          v-if="tab == 2"
          :start-date="startDate"
          :end-date="endDate"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import DailyWaterBalance from "./DailyWaterBalance";
import Meteogram from "./Meteogram";
import Risks from "./Risks";
import SeasonalWaterBalance from "./SeasonalWaterBalance";
export default {
  props: ["startDate", "endDate"],
  components: { DailyWaterBalance, Meteogram, Risks, SeasonalWaterBalance },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.ui.stationDialog;
      },
      set(val) {
        if (!val) this.tab = 0;
        this.$store.commit("ui/SET_STATION_DIALOG", val);
      },
    },
  },
};
</script>