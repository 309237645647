<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  mixins: [reactiveProp],
  props: ["stacked", "name"],
  extends: Bar,
  data() {
    let vm = this;
    return {
      options: {
        title: {
          display: false,
          text: vm.name,
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              id: "A",
              type: "linear",
              position: "left",
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Rai",
              },
            },
            {
              id: "B",
              type: "linear",
              position: "right",
            },
          ],
        },
        hover: {
          onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = "pointer";
            else e.target.style.cursor = "default";
          },
        },
      },
    };
  },
  methods: {
    handleClick(c, i) {},
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
