<template>
  <v-row no-gutters class="align-center">
    <v-col cols="3">
      <v-select
        item-text="text"
        style="max-width: 120px"
        :items="items"
        item-value="value"
        v-model="params.per_page"
        @change="$emit('handle-change-per-page')"
      ></v-select>
    </v-col>
    <v-col cols="9">
      <v-pagination
        class="justify-sm-start"
        v-model="params.page"
        :length="length"
        total-visible="10"
        @input="$emit('handle-change-page')"
      ></v-pagination>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["length", "params"],
  data() {
    return {
      items: [
        {
          value: 20,
          text: "20/page",
        },
        {
          value: 50,
          text: "50/page",
        },
        {
          value: 100,
          text: "100/page",
        },
        {
          value: 500,
          text: "500/page",
        },
        {
          value: 1000,
          text: "1000/page",
        },
        {
          value: 2000,
          text: "2000/page",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss"></style>
