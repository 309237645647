<template>
  <v-container
    fluid
    style="height: 500px; overflow-y: auto; width: 100%; overflow-x: hidden"
  >
    <template v-if="data">
      <h3 class="py-1">Station</h3>
      <v-row
        v-for="prop in [
          'Id',
          'Latitude',
          'Longitude',
          'Altitude',
          'Name',
          'CreatedAt',
          'UpdatedAt',
        ]"
        :key="prop"
        dense
      >
        <v-col class="py-0" cols="4">{{ prop }}</v-col>
        <v-col class="py-0" cols="5">{{ data.VWSData[prop] || "None" }}</v-col>
      </v-row>
      <v-row dense class="mt-4">
        <v-col class="py-0" cols="4">
          <span style="line-height: 50px">Pictogram</span>
        </v-col>
        <v-col class="py-0" cols="5">
          <img src="06_iday.png" alt="" width="50" height="50" />
        </v-col>
      </v-row>
      <h3 class="py-1 mt-3">Daily Aggregate</h3>
      <v-row dense>
        <v-col class="py-0" cols="4">Time</v-col>
        <v-col class="py-0" cols="5">{{
          data.WeatherComputedDaily.Time | datetime
        }}</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">ETO</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily
            .EvapotranspirationGrassreferenceET0CalcDaySum
        }}</v-col>
        <v-col class="py-0" cols="2">[mm/day]</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">Balance</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily.WaterBalanceET0CalcDaySum
        }}</v-col>
        <v-col class="py-0" cols="2">[mm/day]</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">Precipitation</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily.PrecipitationTotalRawDaySum
        }}</v-col>
        <v-col class="py-0" cols="2">[mm/day]</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">Temp Min</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily.TemperatureAir2mRawDayMin
        }}</v-col>
        <v-col class="py-0" cols="2">[°C]</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">Temp Max</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily.TemperatureAir2mRawDayMax
        }}</v-col>
        <v-col class="py-0" cols="2">[°C]</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">Temp Avg</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily.TemperatureAir2mRawDayMean
        }}</v-col>
        <v-col class="py-0" cols="2">[°C]</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">Temp Dew Min</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily.TemperatureDewpoint2mRawDayMin
        }}</v-col>
        <v-col class="py-0" cols="2">[°C]</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">Temp Dew Max</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily.TemperatureDewpoint2mRawDayMax
        }}</v-col>
        <v-col class="py-0" cols="2">[°C]</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">Sunshine Time</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily.SunshineDurationRawDaySum
        }}</v-col>
        <v-col class="py-0" cols="2">[min/day]</v-col>
      </v-row>
      <v-row dense>
        <v-col class="py-0" cols="4">Time UTC</v-col>
        <v-col class="py-0" cols="6">{{
          data.WeatherComputedDaily.TimeUtc | datetime
        }}</v-col>
      </v-row>
      <v-row class="mt-3" dense>
        <v-col cols="12" style="text-align: center">
          <v-btn
            color="primary"
            @click="showStationDialog(data.VWSData.Id)"
            small
            >DETAIL</v-btn
          ></v-col
        >
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { getWeatherStationTooltipData } from "@/api/data";
import farms from "@/configs/farms";
export default {
  props: ["id", "date"],
  data() {
    return {
      data: null,
      localDate: null,
      onClick: undefined,
    };
  },
  methods: {
    showStationDialog(id) {
      this.$store.commit("data/SET_CURRENT_STATION", id);
      this.$store.commit("ui/SET_STATION_DIALOG", true);
    },
    async getData() {
      try {
        this.$loader(true);

        const { data } = await getWeatherStationTooltipData(
          176,
          this.localDate
        );
        this.data = data;
        this.$loader(false);
      } catch (error) {
        this.$snackbar("An error has occured", "error");
        this.$loader(false);
      }
    },
  },
  created() {
    this.localDate = this.date;
    this.getData();
    this.$eventBus.$on("date-change", (e) => {
      this.localDate = e;
      this.getData();
    });
  },
};
</script>

<style scoped>
.title-text {
  font-weight: 600;
  font-size: 14px;
}

.popup__image {
  width: auto;
  height: 100%;
  margin: 0 5px;
  object-fit: contain;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 7px;
}

.layer-popup__container {
  padding: 10px 15px;
}
</style>
