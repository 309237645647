import { polygon, centroid } from "@turf/turf";
const state = {
    map: null,
    ready: false,
    centerOfFarms: null,
    layers: {
        ci: [],
        evi: [],
        ndvi: [],
        psri: [],
        savi: [],
    },
    hoveredFarm: null,
};
const mutations = {
    SET_LAYERS(state, { layer, data }) {
        state.layers[layer] = data;
    },
    INIT_MAP(state, payload) {
        state.map = payload;
    },
    SET_READY(state, payload) {
        state.ready = payload;
    },
    SET_CENTER_OF_FARMS(state, payload) {
        state.centerOfFarms = payload;
    },
};
const actions = {
    addFarmsLayer({ state }) {
        state.map.addLayer({
            id: "farms",
            type: "line",
            source: {
                type: "geojson",
                data: null,
            },
            paint: {
                "line-width": 1,
                "line-color": "#F44336",
                "line-opacity": 1,
            },
        });
    },
    addHoveredFarmLayer({ state }) {
        state.map.addLayer({
            id: "hovered_farm",
            type: "fill",
            source: {
                type: "geojson",
                data: null,
            },
            paint: {
                "fill-color": "#F44336",
                "fill-opacity": 1,
            },
        });
    },
    removeLayer({ state }, layerName) {
        if (state.map.getLayer(layerName)) {
            state.map.removeLayer(layerName);
        }
        if (state.map.getSource(layerName)) {
            state.map.removeSource(layerName);
        }
    },
    addFarms({ state, dispatch }, farms) {
        state.map.getSource("farms").setData(null);
        state.map.getSource("farms").setData({
            type: "FeatureCollection",
            features: farms.map((item) => ({
                type: "Feature",
                geometry: item.updated_geometry,
            })),
        });

        dispatch("focusToFarms", farms);
    },
    focusToFarms({ state }, farms) {
        if (farms.length > 0)
            state.map.flyTo({
                center: centroid(polygon(farms[0].updated_geometry.coordinates))
                    .geometry.coordinates,
                zoom: 14,
                duration: 0,
            });
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
