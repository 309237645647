import Cookies from "js-cookie";

const TokenKey = "access_token";

export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token);
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}
export function setPermissions(permissions) {
    return localStorage.setItem("permissions", permissions);
}

export function setWorkspace(workspaceId) {
    return Cookies.set("WorkSpaceId", workspaceId)
}

export function getWorkdspace() {
    return Cookies.get("WorkSpaceId");
}

export function removeWorkdspace() {
    return Cookies.remove("WorkSpaceId");
}