<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <v-col class="py-0 px-0" cols="12">
          <v-btn @click="getData" style="float: right" color="primary"
            >REFRESH</v-btn
          ></v-col
        >
      </v-row>

      <v-row
        class="mt-5"
        style="background-color: white; color: black; border-radius: 4px"
      >
        <v-col cols="12">
          <h2>Seasonal Water Balance</h2>
        </v-col>
        <v-col cols="12">
          <div style="height: 400px">
            <LineChart :styles="chartStyle" :chart-data="getChartData()" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import LineChart from "../chart/LineChart";
import chartStyle from "../chart/style";

import { getStationCalculatedDailyData } from "@/api/data";

export default {
  props: ["startDate", "endDate"],
  components: { LineChart },
  data() {
    return {
      chartStyle,

      duration: 67,
      data: [],
      labels: [],
      limit: null,
    };
  },

  methods: {
    async getData() {
      try {
        this.$loader(true);
        const { data } = await getStationCalculatedDailyData(
          this.$store.state.data.currentStation,
          { start_date: this.startDate, end_date: this.endDate }
        );
        this.data = data.map((item) => ({
          Time: item.Time.substr(0, 10),
          WaterBalanceET0CalcDaySum: item.WaterBalanceET0CalcDaySum,
          EvapotranspirationGrassreferenceET0CalcDaySum:
            item.EvapotranspirationGrassreferenceET0CalcDaySum,
          PrecipitationTotalRawDaySum: item.PrecipitationTotalRawDaySum,
        }));
        this.labels = this.data.map((item) => item.Time);
      } catch (error) {
        console.log(error);
        this.$snackbar("An error has occurred", "error");
      } finally {
        this.$loader(false);
      }
    },
    getChartData() {
      const ET0 = {
        label: "ET0 [mm]",
        data: [],
        borderColor: "#4CAF50",
        fill: false,
      };
      const precipitation = {
        label: "Precipitation [mm]",
        data: [],
        borderColor: "#2196F3",
        fill: false,
      };
      const balance = {
        label: "Balance [mm]",
        data: [],
        backgroundColor: "#FF9E80",
      };
      this.data.forEach((item) => {
        ET0.data.push(item.EvapotranspirationGrassreferenceET0CalcDaySum);
        precipitation.data.push(item.PrecipitationTotalRawDaySum);
        balance.data.push(item.WaterBalanceET0CalcDaySum);
      });
      // [ET0.data, precipitation.data, balance.data].forEach((data) => {
      //   data = data.map(((sum) => (value) => (sum += value))(0));
      // });
      ET0.data = ET0.data.map(((sum) => (value) => (sum += value))(0));
      precipitation.data = precipitation.data.map(
        ((sum) => (value) => (sum += value))(0)
      );
      balance.data = balance.data.map(((sum) => (value) => (sum += value))(0));

      return {
        labels: this.labels,
        datasets: [ET0, precipitation, balance],
      };
    },
  },

  mounted() {
    this.getData();
  },
};
</script>