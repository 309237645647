<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col class="pt-0" cols="6">
        <v-text-field
          label="Search"
          prepend-inner-icon="mdi-magnify"
          class="mt-0"
          v-model="params.search"
          @keyup.enter="getFarms({ page: 1, reset: true })"
          style="border-radius: 4px 4px 0px 0px"
          hide-details
        ></v-text-field
      ></v-col>

      <v-col class="pt-0" cols="3">
        <v-autocomplete
          label="State"
          :items="filters.state"
          class="mt-0"
          v-model="params.state"
          @change="getFarms({ page: 1, reset: true })"
          clearable
          style="border-radius: 4px 4px 0px 0px"
          hide-details
        ></v-autocomplete
      ></v-col>
      <v-col class="pt-0" cols="3">
        <v-autocomplete
          label="Taluka"
          :items="filters.taluka"
          class="mt-0"
          v-model="params.taluka"
          @change="getFarms({ page: 1, reset: true })"
          clearable
          style="border-radius: 4px 4px 0px 0px"
          hide-details
        ></v-autocomplete
      ></v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0" cols="3">
        <v-autocomplete
          label="IU Name"
          :items="filters.iu"
          class="mt-0"
          v-model="params.iu"
          @change="getFarms({ page: 1, reset: true })"
          clearable
          style="border-radius: 4px 4px 0px 0px"
          hide-details
        ></v-autocomplete
      ></v-col>
      <v-col class="pt-0" cols="3">
        <v-autocomplete
          label="DOL"
          :items="filters.dol"
          class="mt-0"
          v-model="params.dol"
          @change="getFarms({ page: 1, reset: true })"
          clearable
          style="border-radius: 4px 4px 0px 0px"
          hide-details
        ></v-autocomplete
      ></v-col>
      <v-col class="pt-0" cols="3">
        <v-autocomplete
          label="Cause of loss"
          :items="filters.col"
          class="mt-0"
          v-model="params.col"
          @change="getFarms({ page: 1, reset: true })"
          clearable
          style="border-radius: 4px 4px 0px 0px"
          hide-details
        ></v-autocomplete
      ></v-col>
      <v-col class="pt-0" cols="3">
        <v-autocomplete
          label="Crop"
          :items="filters.crop"
          class="mt-0"
          v-model="params.crop"
          @change="getFarms({ page: 1, reset: true })"
          clearable
          style="border-radius: 4px 4px 0px 0px"
          hide-details
        ></v-autocomplete
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="footer-action d-flex">
        <v-btn
          @click="$store.commit('data/RESET_SELECTED_ITEMS')"
          class="px-2 button-filter mr-4"
          style="min-width: unset"
          outlined
        >
          <v-icon>mdi-filter-remove</v-icon>
        </v-btn>

        <v-btn
          outlined
          @click="getFarms({ page: 1, reset: true })"
          style="min-width: unset"
          class="px-2 button-filter mr-4"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn
          color="#006ec5"
          @click="exportExcel"
          class="mr-4"
          :loading="loading"
          style="color: white"
          >EXPORT EXCEL</v-btn
        >
        <!-- <v-btn
          color="#006ec5"
          @click="
            params.search = '';
            getFarms({page:1,reset:false});
          "
          style="color: white"
          >RESET FILTER</v-btn
        > -->
        <span style="font-size: 14px; line-height: 36px">{{
          selectedItems.length + " selected"
        }}</span>
      </v-col>
      <v-col class="pt-0" cols="3">
        <v-select
          label="Sort By"
          :items="sortBy"
          class="mt-0"
          item-value="id"
          item-text="text"
          v-model="params.sort_by"
          @keyup.enter="getFarms({ page: 1, reset: true })"
          clearable
          style="border-radius: 4px 4px 0px 0px"
          hide-details
        ></v-select
      ></v-col>
      <v-col class="pt-0" cols="3">
        <v-select
          label="Sort Type"
          :items="['asc', 'desc']"
          class="mt-0"
          v-model="params.sort_type"
          @keyup.enter="getFarms({ page: 1, reset: true })"
          style="border-radius: 4px 4px 0px 0px"
          hide-details
        ></v-select
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { exportFarms, getFilters } from "@/api/data";
export default {
  data() {
    return {
      loading: false,
      sortBy: [
        {
          id: "Farmers.name",
          text: "Farmer name",
        },
        {
          id: "father_name",
          text: "Father name",
        },
        {
          id: "state",
          text: "State",
        },
        {
          id: "district",
          text: "District",
        },
        {
          id: "taluka",
          text: "Taluka",
        },
        {
          id: "i_u_name",
          text: "IU Name",
        },
        {
          id: "intimation_no",
          text: "Intimation",
        },
        {
          id: "loss",
          text: "Loss",
        },
        {
          id: "calculated_loss",
          text: "Calculated loss",
        },
      ],
      filters: {
        col: [],
        crop: [],
        dol: [],
        iu: [],
        state: [],
        taluka: [],
      },
    };
  },
  computed: {
    ...mapState("data", ["selectedItems", "params"]),
  },
  methods: {
    ...mapActions("data", ["getFarms"]),
    mapData() {},
    async exportExcel() {
      try {
        if (this.selectedItems.length === 0) {
          this.$snackbar("You must choose at least one farm", "error");
          return;
        }
        this.loading = true;
        const data = await exportFarms({ farms: this.selectedItems });
        const url = URL.createObjectURL(
          new Blob([data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${"farms"}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
        this.$snackbar("An error has occured", "error");
      } finally {
        this.loading = false;
      }
    },
    formatJson(filterVal, jsonData) {
      let i = 0;
      jsonData.forEach((e) => {
        (e.stt = i + 1), (e.year = e.survey_date.substr(0, 4));
        e.survey_date_d = e.survey_date.substr(0, 10);
        e.survey_date_t = e.survey_date.substr(10, 18);
        i = i + 1;
      });
      return jsonData.map((v) =>
        filterVal.map((j) => {
          const k = j ? j.split(".") : [];
          return k.length > 1 ? v[k[0]][k[1]] : v[j];
        })
      );
    },
    async getFilters() {
      const filters = await getFilters();
      filters.col = filters.col.map((item) => item.cause_of_loss);
      filters.crop = filters.crop.map((item) => item.crop_name_id);
      filters.dol = filters.dol.map((item) => item.date_of_loss);
      filters.iu = filters.iu.map((item) => item.i_u_name);
      filters.state = filters.state.map((item) => item.state);
      filters.taluka = filters.taluka.map((item) => item.taluka);
      console.log(filters);
      this.filters = filters;
    },
  },
  created() {
    this.getFilters();
  },
};
</script>
