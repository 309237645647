const state = {
    drawer: true,
    snackbarShow: false,
    snackbarText: "",
    snackbarType: "info",
    loader: false,
    showDialog: false,
    stationDialog: false,
};
const mutations = {
    SET_SHOW_DIALOG(state, payload) {
        state.showDialog = payload;
    },
    SET_DRAWER(state, payload = null) {
        if (payload === null) state.drawer = !state.drawer;
        else state.drawer = payload;
    },
    SET_SNACKBAR_SHOW(state, payload) {
        state.snackbarShow = payload;
    },
    SET_SNACKBAR_TEXT(state, payload) {
        state.snackbarText = payload;
    },
    SET_SNACKBAR_TYPE(state, payload) {
        state.snackbarType = payload;
    },
    SET_LOADER(state, payload) {
        state.loader = payload;
    },
    SET_STATION_DIALOG(state, payload) {
        state.stationDialog = payload;
    },
};
const actions = {
    setDrawer({ commit }, payload) {
        commit("SET_DRAWER", payload);
    },

    setLoader({ commit }, payload) {
        commit("SET_LOADER", payload);
    },
    showSnackbar({ commit }, { text, type, timeout = 6 }) {
        commit("SET_SNACKBAR_TEXT", text);
        commit("SET_SNACKBAR_TYPE", type);
        commit("SET_SNACKBAR_SHOW", true);
        setTimeout(() => commit("SET_SNACKBAR_SHOW", false), timeout);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
