<template>
  <ModuleContainer></ModuleContainer>
</template>

<script>
import { ModuleMixin } from "@skymapglobal/map";
import { ExtendDrawControlMixin } from "@skymapglobal/map-drawer";
import { mapActions } from "vuex";

export default {
  mixins: [ModuleMixin, ExtendDrawControlMixin],

  methods: {
    ...mapActions("data", ["getFarms"]),
    ...mapActions("map", ["addFarmsLayer", "addHoveredFarmLayer"]),
    onInit() {
      this.$store.commit("map/INIT_MAP", this.map);
      this.$store.dispatch("map/addFarmsLayer");
      this.$store.dispatch("map/addHoveredFarmLayer");
      this.getFarms({ page: 1, reset: true });
    },
  },
};
</script>

<style scoped></style>
