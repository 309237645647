import request from "@/utils/request";
import axios from "axios";
const weatherToken =
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjIsImlhdCI6MTYxODU1OTUyM30.3LK_5DO44GZ1Rr155gyku4Ma6iUgQ2DgWP0e6tBgeIA";
const weatherUrl = process.env.VUE_APP_WEATHER_SERVER;
export function getFarms(params) {
    return request({
        url: "/report_insurances",
        method: "get",
        params,
    });
}
export function getFarmDetail(id) {
    return request({
        url: "/report_insurances/" + id,
        method: "get",
    });
}
export function exportFarms(data) {
    return request({
        url: "/report_insurances/export",
        method: "post",
        data,
        responseType: "blob",
    });
}
export function getFilters(id) {
    return request({
        url: "/report_insurances/filters",
        method: "get",
    });
}

export function getWeatherStationTooltipData(id, date) {
    return axios.get(`${weatherUrl}/VWS/${id}/Tooltip`, {
        params: { date },
        headers: {
            authorization: weatherToken,
        },
    });
}
export function getStationCalculatedDailyData(id, params) {
    console.log(params);
    return axios.get(
        `${weatherUrl}/VWS/${id}/CalculatedDaily`,

        {
            params,
            headers: {
                authorization: weatherToken,
            },
        }
    );
}
export function getStationRawHourlyData(id, params) {
    return axios.get(`${weatherUrl}/VWS/${id}/RawHourly`, {
        params,
        headers: {
            authorization: weatherToken,
        },
    });
}

export function getStationRisksData(id) {
    return axios.get(`${weatherUrl}/VWS/${id}/Risks`, {
        headers: {
            authorization: weatherToken,
        },
    });
}
export function getStationCalculatedHourlyData(params) {
    return axios.get(`${weatherUrl}/VWS/Coordinates/CalculatedHourly`, {
        params,
        headers: {
            authorization: weatherToken,
        },
    });
}
