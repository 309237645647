<template>
  <v-navigation-drawer clipped width="50%" v-model="drawer" app>
    <div
      style="
        background-color: #003f71;
        height: 40px;
        position: relative;
        letter-spacing: 5px;
        text-transform: uppercase;
        padding-top: 10px;
        text-align: center;
        color: white;
      "
    >
      <p>FARM LIST</p>
    </div>

    <SidebarSearchFilter />
    <SidebarTable />
    <SidebarFooter />
  </v-navigation-drawer>
</template>

<script>
import SidebarSearchFilter from "./SidebarSearchFilter";
import SidebarFooter from "./SidebarFooter";
import SidebarTable from "./table/SidebarTable";
export default {
  components: { SidebarSearchFilter, SidebarFooter, SidebarTable },
  computed: {
    drawer: {
      get() {
        return this.$store.state.ui.drawer;
      },
      set(val) {
        this.$store.dispatch("ui/setDrawer", val);
      },
    },
  },
};
</script>

<style lang="scss"></style>
