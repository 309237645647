const farms = [
    {
        id: 13905,
        lng: 75.459516271949,
        lat: 18.276725567155,
        distance: 243958.03566493705,
        station_id: 176,
    },
    {
        id: 87,
        lng: 75.848725419492,
        lat: 24.612728596909,
        distance: 264769.4671794139,
        station_id: 142,
    },
    {
        id: 86,
        lng: 75.848857602105,
        lat: 24.613475465514,
        distance: 264853.57159881556,
        station_id: 142,
    },
    {
        id: 92,
        lng: 75.829710653052,
        lat: 24.61999866062,
        distance: 265211.31893128157,
        station_id: 142,
    },
    {
        id: 108,
        lng: 75.852640522644,
        lat: 24.626213379549,
        distance: 266316.97342363826,
        station_id: 142,
    },
    {
        id: 103,
        lng: 75.86007193476,
        lat: 24.628782889852,
        distance: 266739.7441863045,
        station_id: 142,
    },
    {
        id: 126,
        lng: 75.870237238705,
        lat: 24.630103930283,
        distance: 267081.5655800806,
        station_id: 142,
    },
    {
        id: 120,
        lng: 75.894594630226,
        lat: 24.631098168193,
        distance: 267679.02472663164,
        station_id: 142,
    },
    {
        id: 129,
        lng: 75.909740645438,
        lat: 24.639856398352,
        distance: 268947.0363618996,
        station_id: 142,
    },
    {
        id: 79,
        lng: 75.919632400785,
        lat: 24.815788583356,
        distance: 288311.96880314226,
        station_id: 142,
    },
];
export default farms;
