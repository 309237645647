
<template>
  <ag-grid-vue
    style="width: 100%; height: calc(100vh - 347px)"
    class="ag-theme-alpine"
    :columnDefs="columnDefs"
    :rowData="farms"
    :animateRows="true"
    :gridOptions="gridOptions"
    :defaultColDef="defaultColDef"
    @column-resized="onColumnResized"
    rowSelection="multiple"
    @rowSelected="onSelectionChanged"
    @rowDataChanged="rowDataChanged"
    suppressRowClickSelection
  ></ag-grid-vue>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { mapState } from "vuex";
import SidebarTableActions from "./SidebarTableActions";
const isFirstColumn = (params) => {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  const thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};
export default {
  components: { AgGridVue },
  data() {
    return {
      currentItem: "",
      settings: [],
      columnDefs: null,
      defaultColDef: null,
      gridApi: null,
      columnApi: null,
      gridOptions: null,
      rowSelection: null,
    };
  },
  computed: {
    ...mapState("data", ["farms", "selectedItems"]),
  },
  watch: {
    selectedItems(val) {
      if (val.length === 0) this.gridApi.deselectAll();
    },
  },
  methods: {
    ////////////////////////////////
    rowDataChanged() {
      this.gridApi.forEachNode((node) => {
        if (this.selectedItems.includes(node.data.id)) node.setSelected(true);
      });
    },
    onColumnResized() {
      if (this.gridColumnApi) this.gridApi.resetRowHeights();
    },
    // onFirstDataRendered() {
    //   if (this.gridApi) this.gridApi.sizeColumnsToFit();
    // },
    numberFormatter(value) {
      if (value != null)
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return null;
    },
    onSelectionChanged(e) {
      const { id } = e.node.data;
      if (e.node.isSelected()) this.$store.commit("data/ADD_ITEM", id);
      else this.$store.commit("data/REMOVE_ITEM", id);
    },
  },
  created() {},
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  beforeMount() {
    this.gridOptions = {};
    this.defaultColDef = {
      // wrapText: true,
      // autoHeight: true,
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn,
    };
    this.columnDefs = [
      {
        headerName: "Farmer Name",
        field: "farmer_name",
        width: 170,
        pinned: "left",
      },
      {
        headerName: "Intimation",
        field: "intimation_no",
        width: 120,
        pinned: "left",
      },
      {
        headerName: "Father Name",
        field: "father_name",
        width: 160,
      },
      {
        headerName: "State",
        field: "state",
        width: 140,
      },
      {
        headerName: "District",
        field: "district",
        width: 140,
      },
      {
        headerName: "Taluka",
        field: "taluka",
        width: 140,
      },
      {
        headerName: "IU Name",
        field: "i_u_name",
        width: 140,
      },
      {
        headerName: "Crop",
        field: "crop_name_id",
        width: 140,
      },
      {
        headerName: "COL",
        field: "cause_of_loss",
        width: 140,
      },

      {
        headerName: "DOL",
        field: "date_of_loss",
        width: 110,
      },
      {
        headerName: "Analysis Date",
        field: "analysis_date",
        width: 135,
      },
      {
        headerName: "Calculated Loss",
        field: "calculated_loss",
        type: "rightAligned",
        width: 150,
      },

      {
        headerName: "Actions",
        cellRendererFramework: SidebarTableActions,
        width: 98,
        pinned: "right",
        type: "centerAligned",
      },
    ];
  },
};
</script>
<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
.grid-cell-centered {
  text-align: center;
}
.grid-cell-left {
  text-align: left;
}
.grid-cell-right {
  text-align: right;
}
.cell-wrap-text {
  white-space: normal !important;
}
.row-pinned {
  color: #e74c3c;
  font-weight: bold;
}
</style>