import { getFarms, getFarmDetail } from "@/api/data.js";
import farms from "@/configs/farms";
console.log(farms);
const state = {
    farms: [],
    selectedItems: [],
    tableData: [],
    pageSize: 50,
    currentPage: 1,
    currentFarm: null,
    currentStation: null,
    params: {
        search: "",
        page: 1,
        per_page: 50,
        sort_by: "",
        sort_type: "asc",
    },
    pagination: {
        last_page: 1,
    },
};
const getters = {
    selectedItemsLength: (state) => state.selectedItems.length,
    dataLength: (state) => state.farms.length,
    paginationInfo: (state) => {
        return [
            state.pageSize * state.currentPage - state.pageSize + 1,
            state.pageSize * state.currentPage,
        ];
    },
    totalPage: (state) => Math.ceil(state.farms.length / state.pageSize),
};
const mutations = {
    SET_CURRENT_STATION(state, payload) {
        state.currentStation = payload;
    },
    SET_PAGINATION(state, payload) {
        state.pagination = payload;
    },
    SET_CURRENT_FARM(state, payload) {
        state.currentFarm = payload;
    },
    RESET_PAGINATION(state) {
        state.currentPage = 1;
    },
    RESET_SELECTED_ITEMS(state) {
        state.selectedItems = [];
        state.farms.forEach((item) => (item.checked = false));
    },
    SET_SELECTED_ITEM(state, payload) {
        state.selectedItems = payload;
    },
    SELECT_ALL(state) {
        state.selectedItems = Array.from(
            new Set(
                state.selectedItems.concat(state.farms.map((item) => item.id))
            )
        );
    },
    ADD_ITEM(state, payload) {
        const index = state.selectedItems.indexOf(payload);
        if (index === -1) state.selectedItems.push(payload);
    },
    REMOVE_ITEM(state, id) {
        const index = state.selectedItems.indexOf(id);
        if (index > -1) {
            state.selectedItems.splice(index, 1);
        }
    },

    SET_TABLE_DATA(state, payload) {
        state.tableData = payload;
    },
    SET_FARMS(state, payload) {
        state.farms = payload;
    },
    SET_CURRENT_PAGE(state, payload) {
        state.currentPage = payload;
    },
};
const actions = {
    async getFarms({ commit, dispatch, state }, { page, reset }) {
        dispatch("ui/setLoader", true, { root: true });
        try {
            if (page) state.params.page = page;
            if (reset) commit("SET_SELECTED_ITEM", []);
            const { data, meta } = await getFarms(state.params);
            commit("SET_FARMS", data);
            commit("SET_PAGINATION", meta);
            dispatch("map/addFarms", data, { root: true });
        } catch (error) {
            console.log(error);
        } finally {
            dispatch("ui/setLoader", false, { root: true });
        }
    },

    clearData({ commit }) {
        commit("RESET_SELECTED_ITEMS");
        commit("SET_CURRENT_PAGE", 1);
        commit("SET_FARMS", []);
    },
};
export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions,
};
