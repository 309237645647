import router from "./router/index";
import store from "./store/index";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login", "/authentication"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    // start progress bar
    if (to.path != from.path) NProgress.start();

    // set page title
    document.title = getPageTitle(to.name);
    // determine whether the user has logged in
    const token = getToken();
    if (token) {
        if (to.path === "/login" || to.path === "/authentication") {
            // if is logged in, redirect to the home page
            next({ path: "/" });
        } else {
            //determine whether the user has obtained his permission roles through getInfo
            const user = store.state.user.name;
            // const hasRoles =
            //     store.getters.roles && store.getters.roles.length > 0;
            if (user) {
                next();
            } else {
                try {
                    // get user info
                    // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
                    await store.dispatch("user/getInfo");
                    // store.dispatch("permission/getPermissions", menus);
                    // generate accessible routes map based on roles

                    // hack method to ensure that addRoutes is complete
                    // set the replace: true, so the navigation will not leave a history record
                    next({ path: to.path });
                } catch (error) {
                    // remove token and go to login page to re-login

                    await store.dispatch("user/resetToken");
                    next("/login");
                }
            }
            next();
        }
    } else {
        /* has no token*/

        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            if (to.path === "/login") {
                next();
                window.location.href = process.env.VUE_APP_FARM_VIGIL;
            } else if (to.path === "/authentication") {
                await store.dispatch("user/login", to.query.token);
                next("/");
            } else next();
        } else {
            next("/login");
        }
    }
});

router.afterEach(() => {
    NProgress.done();
});
