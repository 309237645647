<template>
    <v-snackbar v-model="snackbar" :color="snackbarType" :timeout="-1" top>
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
            <v-btn
                x-small
                icon
                dark
                fab
                v-bind="attrs"
                @click="snackbar = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            snackbar: false
        };
    },
    computed: {
        ...mapGetters(["snackbarShow", "snackbarType", "snackbarText"])
    },
    watch: {
        snackbarShow(val) {
            this.snackbar = val;
        },
        snackbar(val) {
            this.$store.commit("ui/SET_SNACKBAR_SHOW", val);
        }
    }
};
</script>
