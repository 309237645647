<template>
  <v-app-bar clipped-left app>
    <v-toolbar-title>
      <img
        src="https://cdn.eofactory.ai/unsafe/0x61/https://eofactory.ai/images/logo/EOfactory-logo.png?ver=2.0.46"
        alt=""
        width="110px"
      />
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn color="primary" text>
      {{ $store.state.user.name }}
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("user", ["logout"]),
  },
};
</script>

<style scoped></style>
