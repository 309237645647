import { bbox, centroid } from "@turf/turf";
const state = {
    map: null,
    ready: false,
    centerOfFarms: null,
    layers: {
        ci: [],
        evi: [],
        ndvi: [],
        psri: [],
        savi: [],
    },
    hoveredFarm: null,
};
const mutations = {
    SET_LAYERS(state, { layer, data }) {
        state.layers[layer] = data;
    },
    INIT_MAP(state, payload) {
        state.map = payload;
    },
    SET_READY(state, payload) {
        state.ready = payload;
    },
    SET_CENTER_OF_FARMS(state, payload) {
        state.centerOfFarms = payload;
    },
};
const actions = {
    focusToFarms({ state }) {
        state.map.fitBounds(state.centerOfFarms, { duration: 0 });
    },
    addFarmsToMap({ state, rootState }) {
        state.map.addLayer({
            id: "farms",
            type: "fill",
            source: {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: rootState.data.farms.map((item) => ({
                        type: "Feature",
                        geometry: item.geometry,
                    })),
                },
            },
            paint: {
                "fill-color": "#F44336",
                "fill-opacity": 0.5,
            },
        });
    },
    addFarmBoundingBoxToMap({ state, dispatch }, farm) {
        dispatch("removeLayer", "farm_bounding_box");
        state.map.addLayer({
            id: "farm_bounding_box",
            type: "line",
            source: {
                type: "geojson",
                data: {
                    type: "Feature",
                    geometry: farm.updated_geometry,
                },
            },
            paint: {
                "line-width": 1,
                "line-color": "red",
                "line-opacity": 1,
            },
        });
        state.map.fitBounds(bbox(farm.updated_geometry), { duration: 0 });
    },
    addTileToMap({ state, dispatch }, { tiles, geometry }) {
        dispatch("removeLayer", "farm");
        if (!tiles) return;

        state.map.setCenter(centroid(geometry).geometry.coordinates);
        state.map.setZoom(17);
        state.map.addLayer({
            id: "farm",
            type: "raster",
            bounds: bbox(geometry),
            source: {
                id: "farm",
                type: "raster",
                tiles: [process.env.VUE_APP_TILE_SERVER + tiles],
                bounds: bbox(geometry),
                minzoom: 1,
                maxzoom: 22,
            },

            paint: {
                "raster-opacity": 1,
            },
            minzoom: 4,
            maxzoom: 19,
        });
    },
    resizeMap({ state }) {
        if (state.map) this._vm.$nextTick(() => state.map.resize());
    },
    removeLayer({ state }, layerName) {
        if (state.map.getLayer(layerName)) {
            state.map.removeLayer(layerName);
        }
        if (state.map.getSource(layerName)) {
            state.map.removeSource(layerName);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
