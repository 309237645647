const farmer = {
  prov_namt: "Province",
  amp_namt: "Amphoe",
  tam_namt: "Tambon",
  p_code: "Province code",
  a_code: "Amphoe code",
  t_code: "Tambon code",
  tam_idn: "Id_Tamboncod",
  act_rai_or: "Area size (Rai)",
  moo: "Village",
  lat: "Latitude",
  lng: "Longitude",
  type_name: "Plant type",
  detail_nam: "Rice type",
  breed_name: "Classify rice",
  plant_date: "Planting date",
  produce_da: "Harvesting date",
};
export default farmer;
