<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <v-col class="py-0 px-0" cols="12">
          <v-btn @click="getData" style="float: right" color="primary"
            >REFRESH</v-btn
          ></v-col
        >
      </v-row>

      <v-row
        class="mt-5"
        style="background-color: white; color: black; border-radius: 4px"
      >
        <v-col cols="12">
          <h2>Daily Water Balance</h2>
        </v-col>
        <v-col v-if="limit" cols="12">
          <div style="height: 400px">
            <Bar
              :styles="chartStyle"
              name="Balance"
              :limit="limit"
              :chart-data="getBalanceData()"
            />
          </div>
          <div style="height: 460px" class="mt-7">
            <Bar
              :styles="chartStyle"
              :limit="limit"
              :show-label="true"
              name="ET0 & PrecipitationData"
              :chart-data="getET0andPrecipitationData()"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Bar from "../chart/Bar";
import chartStyle from "../chart/style";

import { getStationCalculatedDailyData } from "@/api/data";
export default {
  props: ["startDate", "endDate"],
  components: { Bar },
  data() {
    return {
      chartStyle,
      duration: 37,
      data: [],
      labels: [],
      limit: null,
    };
  },

  methods: {
    async getData() {
      try {
        this.$loader(true);
        const { data } = await getStationCalculatedDailyData(
          this.$store.state.data.currentStation,
          { start_date: this.startDate, end_date: this.endDate }
        );
        this.data = data.map((item) => ({
          Time: item.Time.substr(0, 10),
          WaterBalanceET0CalcDaySum: item.WaterBalanceET0CalcDaySum,
          EvapotranspirationGrassreferenceET0CalcDaySum:
            item.EvapotranspirationGrassreferenceET0CalcDaySum,
          PrecipitationTotalRawDaySum: item.PrecipitationTotalRawDaySum,
        }));
        this.labels = this.data.map((item) => item.Time);
        this.getLimitData();
      } catch (error) {
        console.log(error);
        this.$snackbar("An error has occurred", "error");
      } finally {
        this.$loader(false);
      }
    },
    getET0andPrecipitationData() {
      const ET0 = {
        label: "ET0 [mm]",
        data: [],
        backgroundColor: "#4CAF50",
        categoryPercentage: 1,
        barPercentage: 1,
      };
      const precipitation = {
        label: "Precipitation [mm]",
        data: [],
        backgroundColor: "#9575CD",
        categoryPercentage: 1,
        barPercentage: 1,
      };
      this.data.forEach((item) => {
        ET0.data.push(
          -Math.abs(item.EvapotranspirationGrassreferenceET0CalcDaySum)
        );
        precipitation.data.push(item.PrecipitationTotalRawDaySum);
      });
      return {
        labels: this.labels,
        datasets: [ET0, precipitation],
      };
    },
    getBalanceData() {
      const balancePositive = {
        label: "Balance Positive [mm]",
        data: [],
        backgroundColor: "#90CAF9",
        categoryPercentage: 1,
        barPercentage: 1,
      };
      const balanceNegative = {
        label: "Balance Negative [mm]",
        data: [],
        backgroundColor: "#F44336",
        categoryPercentage: 1,
        barPercentage: 1,
      };
      this.data.forEach((item) => {
        if (item.WaterBalanceET0CalcDaySum > 0) {
          balancePositive.data.push(item.WaterBalanceET0CalcDaySum);
          balanceNegative.data.push(0);
        } else {
          balancePositive.data.push(0);
          balanceNegative.data.push(item.WaterBalanceET0CalcDaySum);
        }
      });
      return {
        labels: this.labels,
        datasets: [balancePositive, balanceNegative],
      };
    },
    getLimitData() {
      const data = [];
      this.data.forEach((item) => {
        data.push(item.WaterBalanceET0CalcDaySum);
        data.push(item.EvapotranspirationGrassreferenceET0CalcDaySum);
        data.push(item.PrecipitationTotalRawDaySum);
      });
      const min = Math.min.apply(null, data);
      const max = Math.max.apply(null, data);
      this.limit = {
        min: min > 0 ? 0 : Math.floor(min),
        max: max > 0 ? Math.ceil(max) : Math.floor(max),
      };
    },
  },

  mounted() {
    this.getData();
  },
};
</script>