<template>
  <Map>
    <MouseCoordinatesControl position="bottom-left" />
    <GeolocateControl position="bottom-right" />
    <ZoomControl position="bottom-right" />
    <FullScreenControl position="bottom-right" />
    <ScaleBarControl position="bottom-left" />
    <BaseMapControl default-base-map="Satellite" position="bottom-right" />
    <HomeControl position="bottom-right" />
    <MainMapModuleContainer />
    <MainMapTileSelection :tab="tab" />
  </Map>
</template>

<script>
import MainMapTileSelection from "./MainMapTileSelection";
import {
  Map,
  BaseMapControl,
  FullScreenControl,
  HomeControl,
  MouseCoordinatesControl,
  ScaleBarControl,
  ZoomControl,
  GeolocateControl,
} from "@skymapglobal/map";

import { MeasurementControl } from "@skymapglobal/map-measurement";
import { DrawControl, MarkupControl } from "@skymapglobal/map-drawer";
import MainMapModuleContainer from "./MainMapModuleContainer";
export default {
  props: ["tab"],
  components: {
    Map,
    BaseMapControl,
    FullScreenControl,
    HomeControl,
    MouseCoordinatesControl,
    ScaleBarControl,
    ZoomControl,
    GeolocateControl,
    MeasurementControl,
    DrawControl,
    MarkupControl,
    MainMapModuleContainer,
    MainMapTileSelection,
  },
};
</script>
