<template>
  <v-dialog v-model="showDialog" persistent max-width="70%">
    <v-card>
      <v-card-title
        class="py-2"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
      >
        <p class="my-0">Farmer Detail</p>
        <v-icon class="icon-close" color="primary" @click="showDialog = false"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pb-0 px-0">
        <v-container class="py-0" fluid>
          <v-row>
            <Infomation
              :tab.sync="tab"
              :farmer="farmer"
              :reports="reports"
              :sar-reports="sarReports"
            />
            <v-col cols="6">
              <Map :tab="tab" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Infomation from "./Infomation";
import farmer from "./farmer";
import Map from "./Map";
export default {
  data() {
    return {
      reports: [],
      farmer: [],
      sarReports: [],
      tab: 0,
    };
  },
  components: { Infomation, Map },
  computed: {
    ...mapState("data", ["currentFarm", "farms"]),
    ...mapState("dialogMap", ["map"]),
    showDialog: {
      get() {
        return this.$store.state.ui.showDialog;
      },
      set(val) {
        if (!val) this.tab = 0;
        this.$store.commit("ui/SET_SHOW_DIALOG", val);
      },
    },
  },
  methods: {
    ...mapActions("dialogMap", [
      "addTileToMap",
      "addFarmBoundingBoxToMap",
      "resizeMap",
    ]),
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.resizeMap();
        if (!this.map)
          setTimeout(() => {
            // this.addTileToMap({
            //   tiles: farm.report.layers,
            //   geometry: farm.geometry,
            //   property: "ndvi",
            // });
            this.resizeMap().then(() => {
              this.addFarmBoundingBoxToMap(this.currentFarm);
            });
          }, 1000);
        else {
          //   this.addTileToMap({
          //     tiles: farm.report.layers,
          //     geometry: farm.geometry,
          //     property: "ndvi",
          //   });
          this.addFarmBoundingBoxToMap(this.currentFarm);
        }
      } else {
        this.reports = [];
        this.farmer = [];
        this.sarReports = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.drop {
  text-align: center;
  border: 3px dashed #bbb;
  padding: 20px 0;
}
.icon-close {
  color: white;

  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 18px;
}
</style>
