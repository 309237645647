<template>
  <div class="sidebar-footer">
    <v-container class="py-0" fluid>
      <Pagination
        :length="pagination.last_page"
        :params="params"
        @handle-change-page="getFarms({ page: null, reset: false })"
        @handle-change-per-page="getFarms({ page: 1, reset: true })"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },

  methods: {
    ...mapActions("data", ["getFarms"]),
  },
  computed: {
    ...mapState("data", ["pagination", "params"]),
  },
};
</script>
