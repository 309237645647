<template>
  <router-view></router-view>
</template>

<script>
import Loader from "@/components/Loader";
export default {
  components: { Loader },
  name: "App",
};
</script>
