<template>
  <v-card
    class="tile-selection"
    v-if="tab !== 'farmer' && tab !== 'farm'"
    width="140px"
  >
    <v-card-text class="py-1">
      <v-radio-group class="mt-0" hide-details v-model="selectedDate">
        <v-radio v-for="n in date" :key="n" :label="n" :value="n"></v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["tab"],
  data() {
    return {
      selectedDate: "",
    };
  },

  computed: {
    ...mapState("data", ["currentFarm"]),
    ...mapState("ui", ["showDialog"]),
    date() {
      return this.currentFarm.reports.map((item) => item.date);
    },
    tiles() {
      if (this.tab !== "farmer" && this.tab !== "farm") {
        const report = this.currentFarm.reports.find(
          (item) => item.date == this.selectedDate
        );
        return report ? report.layers[this.tab] : null;
      }
      return null;
    },
  },
  methods: {
    ...mapActions("dialogMap", ["addTileToMap"]),
  },
  watch: {
    tab() {
      this.selectedDate = this.date[0];
    },
    tiles() {
      this.addTileToMap({
        tiles: this.tiles,
        geometry: this.currentFarm.updated_geometry,
      });
    },
    selectedDate(val) {
      this.$eventBus.$emit("date-change", val);
    },
  },
};
</script>
<style lang="scss">
.tile-selection {
  z-index: 99999;
  position: absolute !important;
  top: 10px;
  right: 10px;
}
</style>
