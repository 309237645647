<template>
  <div>
    <v-icon class="mr-2" @click="showDetail">mdi-alert-circle-outline</v-icon>
    <v-icon @click="focus">mdi-eye</v-icon>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import { centroid, polygon } from "@turf/turf";
export default Vue.extend({
  computed: {
    ...mapState("map", ["map"]),
  },
  methods: {
    focus() {
      this.map.flyTo({
        center: centroid(polygon(this.params.data.updated_geometry.coordinates))
          .geometry.coordinates,
        zoom: 17,
        duration: 0,
      });
    },
    showDetail() {
      this.$store.commit("ui/SET_SHOW_DIALOG", true);
      this.$store.commit("data/SET_CURRENT_FARM", { ...this.params.data });
    },
  },
});
</script>