import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/styles/app.scss";
import "./permission";
const EventBus = new Vue();
Vue.prototype.$eventBus = EventBus;
Vue.config.productionTip = false;
Vue.prototype.$snackbar = (text, type, timeout = 2000) => {
    store.dispatch("ui/showSnackbar", { text, type, timeout });
};
Vue.prototype.$loader = (loader) => {
    store.dispatch("ui/setLoader", loader);
};
Vue.filter("datetime", function(value) {
    return `${value.substr(0, 10)} ${value.substr(11, 8)}`;
});
new Vue({
    router,
    store,
    vuetify,
    render: function(h) {
        return h(App);
    },
}).$mount("#app");
