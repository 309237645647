<template>
  <div>
    <v-container class="mt-10">
      <v-row
        class="mt-5"
        style="background-color: white; color: black; border-radius: 4px"
      >
        <v-col cols="12">
          <h2>Meteogram</h2>
        </v-col>
        <v-col cols="12">
          <div style="height: 400px">
            <TimeSeries
              v-if="fisrtChartLimition"
              :limition="fisrtChartLimition"
              :right-axes="true"
              :styles="chartStyle"
              left-label="Radiation & Sun"
              right-label="Cloud cover"
              :show-label="true"
              :chart-data="getFirstChartData()"
            />
          </div>
          <div style="height: 400px" class="mt-7">
            <TimeSeries
              v-if="secondChartLimition"
              :right-axes="true"
              :limition="secondChartLimition"
              :styles="chartStyle"
              left-label="Relative Humidity"
              right-label="Precipitation"
              :show-label="true"
              :chart-data="getSecondChartData()"
            />
          </div>
          <div style="height: 400px" class="mt-7">
            <TimeSeries
              :limition="{
                maxValueLeft: null,
                stepLeft: null,
                stepRight: null,
                maxValueRight: null,
                maxValueLeft: -10,
              }"
              :styles="chartStyle"
              left-label="Tair & Tdew"
              right-label=""
              :show-label="true"
              :chart-data="getThirdChartData()"
            />
          </div>
          <div style="height: 400px" class="mt-7">
            <TimeSeries
              :limition="{
                maxValueLeft: null,
                stepLeft: null,
                stepRight: null,
                maxValueRight: null,
              }"
              :styles="chartStyle"
              left-label="Wind"
              right-label=""
              :show-label="true"
              :chart-data="getFourthChartData()"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import TimeSeries from "../chart/TimeSeries";
import chartStyle from "../chart/style";
import {
  getStationCalculatedDailyData,
  getStationRawHourlyData,
} from "@/api/data";

export default {
  props: ["startDate", "endDate"],
  components: { TimeSeries },
  data() {
    return {
      chartStyle,
      hourlyData: [],
      calculatedDailyData: [],
      labels: [],
      limit: [],
      RadiationGlobalHorizontalMean: [],
      SunshineDurationRawDaySum: [],
      CloudTotal: [],
      PrecipitationTotalRawDaySum: [],
      HumidityRelative2m: [],
      dataForFirstLimition: [],
      fisrtChartLimition: null,
      secondChartLimition: null,
      TemperatureAir2m: [],
      TemperatureDewpoint2m: [],
      WindSpeed10m: [],
      WindGust10m: [],
    };
  },

  methods: {
    async getData() {
      try {
        this.$loader(true);
        const id = this.$store.state.data.currentStation;
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
        };
        const [calculatedDailyData, hourlyData] = (
          await Promise.all([
            getStationCalculatedDailyData(id, params),
            getStationRawHourlyData(id, params),
          ])
        ).map((item) => item.data);
        calculatedDailyData.forEach((item) => {
          item.Time = `${item.Time.substr(0, 10)} ${item.Time.substr(11, 8)}`;
          this.SunshineDurationRawDaySum.push({
            x: item.Time,
            y: item.SunshineDurationRawDaySum,
          });
          this.PrecipitationTotalRawDaySum.push({
            x: item.Time,
            y: item.PrecipitationTotalRawDaySum,
          });

          this.dataForFirstLimition.push(item.SunshineDurationRawDaySum);
          this.labels.push(item.Time);
        });
        hourlyData.forEach((item) => {
          item.Time = `${item.Time.substr(0, 10)} ${item.Time.substr(11, 8)}`;
          this.CloudTotal.push({
            x: item.Time,
            y: item.CloudTotal,
          });
          this.RadiationGlobalHorizontalMean.push({
            x: item.Time,
            y: item.RadiationGlobalHorizontalMean,
          });
          this.HumidityRelative2m.push({
            x: item.Time,
            y: item.HumidityRelative2m,
          });
          this.TemperatureAir2m.push({
            x: item.Time,
            y: item.TemperatureAir2m,
          });
          this.TemperatureDewpoint2m.push({
            x: item.Time,
            y: item.TemperatureDewpoint2m,
          });
          this.WindSpeed10m.push({
            x: item.Time,
            y: item.WindSpeed10m,
          });
          this.WindGust10m.push({
            x: item.Time,
            y: item.WindGust10m,
          });
          this.limit.push({
            x: item.Time,
            y: 5.14,
          });
          this.dataForFirstLimition.push(item.RadiationGlobalHorizontalMean);
        });
        this.getFirstChartLimition();
        this.getSecondChartLimition();
      } catch (error) {
        console.log(error);
        this.$snackbar("An error has occurred", "error");
      } finally {
        this.$loader(false);
      }
    },
    getFirstChartData() {
      const RadiationGlobalHorizontalMean = {
        label: "Radiation [W/m²]",
        data: [...this.RadiationGlobalHorizontalMean],
        yAxisID: "A",
        borderColor: "#FF9800",
        fill: false,
      };
      const SunshineDurationRawDaySum = {
        label: "Sunshine [min]",
        data: [...this.SunshineDurationRawDaySum],
        yAxisID: "A",
        borderColor: "#FFEB3B",
        fill: false,
      };
      const CloudTotal = {
        label: "Cloud cover [%]",
        data: [...this.CloudTotal],
        yAxisID: "B",
        backgroundColor: "#E0E0E0",
        fill: true,
        borderWidth: 1,
      };
      return {
        labels: this.labels,
        datasets: [
          RadiationGlobalHorizontalMean,
          SunshineDurationRawDaySum,
          CloudTotal,
        ],
      };
    },
    getFirstChartLimition() {
      const max = Math.max.apply(null, this.dataForFirstLimition);
      let maxValueLeft = 0;
      let stepLeft = 0;
      let stepRight = 0;
      if (max > 1200) {
        maxValueLeft = 1500;
        stepLeft = 250;
        stepRight = 16.67;
      } else if (1000 < max && max <= 1200) {
        maxValueLeft = 1200;
        stepLeft = 200;
        stepRight = 16.67;
      } else if (500 < max && max <= 1000) {
        maxValueLeft = 1000;
        stepLeft = 200;
        stepRight = 20;
      } else {
        maxValueLeft = 500;
        stepLeft = 100;
        stepRight = 20;
      }
      this.fisrtChartLimition = {
        maxValueLeft,
        stepLeft,
        stepRight,
        maxValueRight: 100,
      };
    },
    getSecondChartData() {
      const HumidityRelative2m = {
        label: "Relative Humidity [%]",
        data: [...this.HumidityRelative2m],
        yAxisID: "A",
        borderColor: "#00BCD4",
        fill: false,
      };
      const PrecipitationTotalRawDaySum = {
        label: "Daily Precipitation [mm]",
        data: [...this.PrecipitationTotalRawDaySum],
        yAxisID: "B",
        backgroundColor: "#2196F3",
        type: "bar",
      };

      return {
        labels: this.labels,
        datasets: [HumidityRelative2m, PrecipitationTotalRawDaySum],
      };
    },
    getSecondChartLimition() {
      console.log(this.PrecipitationTotalRawDaySum);
      const max = Math.max.apply(
        null,
        this.PrecipitationTotalRawDaySum.map((i) => i.y)
      );
      console.log(max);
      let maxValueLeft = 100;
      let stepLeft = 20;
      let stepRight = 0;
      let maxValueRight = 0;
      if (max <= 5) {
        maxValueRight = 5;
        stepRight = 1;
      } else if (5 < max && max <= 10) {
        maxValueRight = 10;
        stepRight = 2;
      } else if (10 < max && max <= 25) {
        maxValueRight = 25;
        stepRight = 5;
      } else if (25 < max && max <= 50) {
        maxValueRight = 50;
        stepRight = 10;
      } else if (50 < max && max <= 100) {
        maxValueRight = 100;
        stepRight = 20;
      } else {
        maxValueRight = 250;
        stepRight = 50;
      }
      this.secondChartLimition = {
        maxValueLeft,
        stepLeft,
        stepRight,
        maxValueRight,
      };
      console.log(this.secondChartLimition);
    },
    getThirdChartData() {
      const TemperatureAir2m = {
        label: "Temperature [C]",
        data: [...this.TemperatureAir2m],
        yAxisID: "A",
        borderColor: "#F44336",
        fill: false,
      };
      const TemperatureDewpoint2m = {
        label: "Dew Point [C]",
        data: [...this.TemperatureDewpoint2m],
        yAxisID: "A",
        borderColor: "#9C27B0",
        fill: false,
      };
      console.log(TemperatureDewpoint2m);
      return {
        labels: this.labels,
        datasets: [TemperatureAir2m, TemperatureDewpoint2m],
      };
    },
    getFourthChartData() {
      const WindSpeed10m = {
        label: "Wind Speed [m/s]",
        data: [...this.WindSpeed10m],
        yAxisID: "A",
        borderColor: "#4CAF50",
        fill: false,
      };
      const WindGust10m = {
        label: "Wind Gust [m/s]",
        data: [...this.WindGust10m],
        yAxisID: "A",
        borderColor: "#673AB7",
        fill: false,
      };
      const limit = {
        label: "Limit for pesticide application",
        data: [...this.limit],
        yAxisID: "A",
        borderColor: "#424242",
        fill: false,
      };
      return {
        labels: this.labels,
        datasets: [WindSpeed10m, WindGust10m, limit],
      };
    },
  },

  mounted() {
    this.getData();
  },
};
</script>