<template>
  <v-main>
    <Map>
      <span class="toggle-drawer">
        <v-btn v-if="drawer" @click="drawer = false" fab small
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn v-else fab small @click="drawer = true"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </span>
      <MouseCoordinatesControl position="bottom-left" />
      <GeolocateControl position="bottom-right" />
      <ZoomControl position="bottom-right" />
      <FullScreenControl position="bottom-right" />
      <ScaleBarControl position="bottom-left" />

      <DrawControl>
        <MarkupControl position="bottom-right" />
        <MeasurementControl position="bottom-right" />
        <MainMapModuleContainer />
      </DrawControl>
      <BaseMapControl default-base-map="Satellite" position="bottom-right" />
      <HomeControl position="bottom-right" />
      <!-- <LayerManagementModule position="bottom-right" /> -->
    </Map>
  </v-main>
</template>

<script>
import {
  Map,
  BaseMapControl,
  FullScreenControl,
  HomeControl,
  MouseCoordinatesControl,
  ScaleBarControl,
  ZoomControl,
  GeolocateControl,
} from "@skymapglobal/map";

import MainMapModuleContainer from "./MainMapModuleContainer";
import { MeasurementControl } from "@skymapglobal/map-measurement";
import { DrawControl, MarkupControl } from "@skymapglobal/map-drawer";
import { LayerManagementModule } from "@skymapglobal/map-layer-management";
import { mapGetters } from "vuex";
export default {
  components: {
    Map,
    BaseMapControl,
    FullScreenControl,
    HomeControl,
    MouseCoordinatesControl,
    ScaleBarControl,
    ZoomControl,
    GeolocateControl,
    MeasurementControl,

    DrawControl,

    MainMapModuleContainer,
    LayerManagementModule,

    MarkupControl,
  },

  computed: {
    ...mapGetters(["isAuth"]),
    drawer: {
      get() {
        return this.$store.state.ui.drawer;
      },
      set(val) {
        this.$store.dispatch("ui/setDrawer", val);
      },
    },
  },
};
</script>

<style lang="css" scoped>
.toggle-drawer {
  position: absolute;
  top: 50%;
  left: 5px;
  z-index: 99;
}
</style>
