import axios from "axios";
import store from "@/store";
import { getToken } from "@/utils/auth";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (store.getters.isAuth) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const { data } = response;
    return data;
  },
  (error) => {
    if (error.response.status === 422) {
      store.dispatch("ui/showSnackbar", {
        text:
          error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ][0],
        type: "error",
        timeout: 2000,
      });
    } else if (error.response.status === 400)
      store.dispatch("ui/showSnackbar", {
        text: error.response.data.message,
        type: "error",
        timeout: 2000,
      });
    else if (error.response.status === 401) {
      store.dispatch("ui/showSnackbar", {
        text: "You are logged out",
        type: "error",
        timeout: 2000,
      });
      store.dispatch("user/resetToken").then(() => {
        window.location.reload();
      });
    } else if (error.response.status === 404) router.push("/404");
    return Promise.reject(error);
  }
);

export default service;
