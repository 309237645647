<template>
  <v-app>
    <v-main style="background:#303030">
      <v-container
        style="height:100%;align-items:center"
        class="justify-center d-flex  "
      >
        <v-col cols="6">
          <v-card width="450px" class="mx-auto">
            <v-card-title
              ><p class="my-0" style="width:100%;text-align:center">
                SKYMAP GLOBAL
              </p></v-card-title
            >

            <v-card-text>
              <v-form ref="form" lazy-validation v-model="valid">
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Username"
                  v-model="form.username"
                  :disabled="loading"
                  :rules="rule.nameRules"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-lock"
                  label="Password"
                  type="password"
                  v-model="form.password"
                  :disabled="loading"
                  :rules="rule.passwordRules"
                ></v-text-field
              ></v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                @click="login"
                :loading="loading"
                block
                dark
                style="width: 150px"
                class="d-block mx-auto"
              >
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-container>
    </v-main>
    <v-footer style="background:#212121;color:white" app>
      <p style="text-align:center;width:100%" class="my-0">
        ©Skymap Global 2020. All rights reserved.
      </p>
    </v-footer>
    <Snackbar />
  </v-app>
</template>
<script>
import Snackbar from "@/components/Snackbar";

export default {
  components: { Snackbar },
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        username: "admin",
        password: "123456",
      },
      rule: {
        nameRules: [(v) => !!v || "Username is required"],
        passwordRules: [(v) => !!v || "Password is required"],
      },
    };
  },
  methods: {
    login() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;
      this.$store
        .dispatch("user/login", this.form)
        .then(() => {
          this.loading = false;
          this.$router.push("/");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 403) {
            this.$snackbar("The username or password is incorrect", "error");
          }
        });
    },
  },
};
</script>
<style lang="css">
.login-form {
  max-width: 500px;
}
</style>
