<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
export default {
  extends: Line,
  mixins: [reactiveProp],
  props: [
    "name",
    "limition",
    "rightAxes",
    "paddingLeft",
    "paddingRight",
    "showLabel",
    "leftLabel",
    "rightLabel",
  ],
  data() {
    let vm = this;

    return {
      options: {
        elements: {
          point: {
            radius: 0,
          },
        },
        layout: {
          padding: {
            right: vm.paddingRight || 0,
            left: vm.paddingLeft || 0,
          },
        },

        tooltips: {
          enabled: true,
        },
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
                displayFormats: {
                  day: "YYYY-MM-DD",
                },
              },
              distribution: "linear",
              ticks: {
                display: vm.showLabel,
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90,
              },
            },
          ],
          yAxes: [
            {
              id: "A",
              type: "linear",
              position: "left",
              ticks: {
                suggestedMin: vm.limition.maxValueLeft || null,
                suggestedMax: vm.limition.maxValueLeft,
                stepSize: vm.limition.stepLeft,
                beginAtZero: true,
              },
              scaleLabel: {
                display: vm.leftLabel,
                labelString: vm.leftLabel,
              },
            },

            {
              id: "B",
              type: "linear",
              scaleLabel: {
                display: true,
                labelString: vm.rightLabel,
              },
              position: "right",
              display: vm.rightAxes,
              ticks: {
                stepSize: vm.limition.stepRight,
                suggestedMax: vm.limition.maxValueRight,
                beginAtZero: true,
                userCallback: function (label) {
                  return label;
                },
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
