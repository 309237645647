import { getInfo } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";

const state = {
    token: getToken(),
    name: "",
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_NAME: (state, name) => {
        state.name = name;
    },
};

const actions = {
    login({ commit }, token) {
        commit("SET_TOKEN", token);
        setToken(token);
    },

    // get user info
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo(state.token)
                .then((response) => {
                    const { data } = response;
                    if (!data) {
                        reject("Verification failed, please Login again.");
                    }
                    const { name } = data;
                    commit("SET_NAME", name);
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    // // user logout
    // logout({ commit }) {
    //     return new Promise((resolve, reject) => {
    //         logout()
    //             .then(() => {
    //                 commit("SET_TOKEN", "");
    //                 removeToken();
    //                 window.location.reload();
    //                 resolve();
    //             })
    //             .catch((error) => {
    //                 reject(error);
    //             });
    //     });
    // },

    // remove token
    resetToken({ commit }) {
        return new Promise((resolve) => {
            commit("SET_TOKEN", "");
            removeToken();
            resolve();
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
