<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  mixins: [reactiveProp],
  props: ["name", "limit", "showLabel"],
  extends: Bar,
  data() {
    let vm = this;
    console.log(vm);
    return {
      options: {
        title: {
          display: true,
          text: vm.name,
          position: "left",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                display: vm.showLabel,
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                suggestedMin: vm.limit.min,
                suggestedMax: vm.limit.max,
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
