<template>
  <v-container class="mt-10">
    <v-row
      class="mt-5"
      style="background-color: white; color: black; border-radius: 4px"
    >
      <v-col cols="12">
        <h2>Risks</h2>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :dark="false"
          :headers="[
            { text: 'Risk Name', value: 'name' },
            { text: 'Risk', value: 'value' },
          ]"
          :items="data"
          disable-filtering
          disable-pagination
          disable-sort
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:item.value="{ item }">
            <v-icon
              v-for="(n, i) in item.level"
              :color="i < item.value ? item.alert : ''"
              :key="n"
              >mdi-alert-circle</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getStationRisksData } from "@/api/data";
const riskLevel = {
  FlashFloodRiskLevel: 5,
  SprayDriftRiskLevel: 4,
  RegionalUnitDroughtRiskLevel: 5,
};
export default {
  props: ["startDate", "endDate"],
  data() {
    return {
      data: [],
    };
  },
  methods: {
    async getData() {
      try {
        this.$loader(true);
        const risks = (
          await getStationRisksData(this.$store.state.data.currentStation, {
            start_date: this.startDate,
            end_date: this.endDate,
          })
        ).data;
        console.log(risks);
        let data = [];
        for (const risk in risks) {
          data.push({
            name: risk.replace(/([A-Z])/g, " $1"),
            value: risks[risk]["Value"],
            alert: risks[risk]["Alert"],
            level: riskLevel[risk],
          });
        }
        this.data = data;
      } catch (error) {
        this.$snackbar("An error has occurred", "error");
      } finally {
        this.$loader(false);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>