<template>
    <ModuleContainer></ModuleContainer>
</template>

<script>
import { ModuleMixin } from "@skymapglobal/map";
import { mapActions } from "vuex";

export default {
    mixins: [ModuleMixin],

    methods: {
        onInit() {
            this.$store.commit("dialogMap/INIT_MAP", this.map);
            this.$store.commit("dialogMap/SET_READY", true);
        },
    },
};
</script>

<style scoped></style>
