<template>
  <v-overlay style="z-index:999999999999999999999999999" :value="loader">
    <v-progress-circular
      indeterminate
      color="primary"
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["loader"]),
  },
};
</script>
