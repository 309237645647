<template>
  <v-col cols="6">
    <v-tabs show-arrows v-model="currentTab" fixed-tabs height="30px">
      <v-tab href="#farmer">Farmer</v-tab>
      <v-tab href="#farm">Farm</v-tab>
      <v-tab href="#ndvi">NDVI</v-tab>
      <v-tab href="#savi">SAVI</v-tab>
      <v-tab href="#ci">CI</v-tab>
      <v-tab href="#evi">EVI</v-tab>
      <v-tab href="#psri">PSRI</v-tab>
      <v-tab href="#sipi">SIPI</v-tab>
      <v-tab href="#weather">Weather</v-tab>
    </v-tabs>

    <v-simple-table height="500px" v-if="tab === 'farmer'" style="width: 100%">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Property</th>
            <th class="text-left">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Farmer Name</td>
            <td>{{ currentFarm.farmer_name }}</td>
          </tr>
          <tr>
            <td>Father Name</td>
            <td>{{ currentFarm.father_name }}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>{{ currentFarm.farmer_mobile_no }}</td>
          </tr>
          <tr>
            <td>Aadhar no</td>
            <td>{{ currentFarm.farmer_aadhar_no }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table height="500px" v-if="tab === 'farm'" style="width: 100%">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Property</th>
            <th class="text-left">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>State</td>
            <td>{{ currentFarm.state }}</td>
          </tr>
          <tr>
            <td>District</td>
            <td>{{ currentFarm.district }}</td>
          </tr>
          <tr>
            <td>Taluka</td>
            <td>{{ currentFarm.taluka }}</td>
          </tr>
          <tr>
            <td>Village</td>
            <td>{{ currentFarm.village }}</td>
          </tr>

          <tr>
            <td>Khasra no</td>
            <td>{{ currentFarm.khasra_no }}</td>
          </tr>
          <tr>
            <td>IU name</td>
            <td>{{ currentFarm.i_u_name }}</td>
          </tr>
          <tr>
            <td>Intimation No</td>
            <td>{{ currentFarm.intimation_no }}</td>
          </tr>
          <tr>
            <td>Area in Ha</td>
            <td>{{ currentFarm.area_in_acres * 0.404685642 }}</td>
          </tr>
          <tr>
            <td>Insured area in Ha</td>
            <td>
              {{
                currentFarm.unit === "in Hectares"
                  ? currentFarm.insured_area
                  : currentFarm.insured_area * 0.404685642
              }}
            </td>
          </tr>
          <tr>
            <td>Affected area in Ha</td>
            <td>
              {{
                currentFarm.unit === "in Hectares"
                  ? currentFarm.affected_area
                  : currentFarm.affected_area * 0.404685642
              }}
            </td>
          </tr>

          <tr>
            <td>Loss %</td>
            <td>{{ currentFarm.loss }}</td>
          </tr>
          <tr>
            <td>Sowing Date</td>
            <td>{{ currentFarm.sowing_date }}</td>
          </tr>
          <tr>
            <td>Stage of Crop</td>
            <td>
              {{ currentFarm.crop_stage ? currentFarm.crop_stage.name : "" }}
            </td>
          </tr>
          <tr>
            <td>Date of Intimation</td>
            <td>{{ currentFarm.date_of_intimation }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row
      style="height: 500px; overflow-y: auto"
      v-if="['ndvi', 'savi', 'ci', 'evi', 'psri', 'sipi'].includes(tab)"
    >
      <v-col cols="12" v-if="tab === 'ndvi'" class="py-0" style="height: 500px">
        <Chart
          v-if="tab === 'ndvi'"
          name="NDVI"
          :styles="chartStyles"
          :chart-data="genChartData('ndvi')"
        />
      </v-col>
      <v-col cols="12" v-if="tab === 'savi'" class="py-0" style="height: 500px">
        <Chart
          v-if="tab === 'savi'"
          name="SAVI"
          :styles="chartStyles"
          :chart-data="genChartData('savi')"
        />
      </v-col>
      <v-col cols="12" v-if="tab === 'ci'" class="py-0" style="height: 500px">
        <Chart
          v-if="tab === 'ci'"
          name="CI GREEN"
          :styles="chartStyles"
          :chart-data="genChartData('ci_green')"
        />
      </v-col>
      <v-col cols="12" v-if="tab === 'evi'" class="py-0" style="height: 500px">
        <Chart
          v-if="tab === 'evi'"
          name="EVI"
          :styles="chartStyles"
          :chart-data="genChartData('evi')"
        />
      </v-col>
      <v-col cols="12" v-if="tab === 'psri'" class="py-0" style="height: 500px">
        <Chart
          v-if="tab === 'psri'"
          name="PSRI"
          :styles="chartStyles"
          :chart-data="genChartData('psri')"
      /></v-col>
      <v-col cols="12" v-if="tab === 'sipi'" class="py-0" style="height: 500px">
        <Chart
          name="SIPI"
          :styles="chartStyles"
          :chart-data="genChartData('sipi')"
        />
      </v-col>
    </v-row>
    <MapStationPopup
      :id="currentFarm.id"
      v-if="tab === 'weather'"
      :date="currentFarm.reports[0].date"
    />
    <StationDialog
      :start-date="currentFarm.reports[0].date"
      :end-date="currentFarm.reports[1].date"
    />
  </v-col>
</template>
<script>
import { mapState } from "vuex";
import { crop_property_ranges } from "./crop.js";
import Chart from "./Chart";
import MapStationPopup from "./MapStationPopup";
import StationDialog from "./station/StationDialog";
export default {
  props: ["tab"],
  components: { Chart, MapStationPopup, StationDialog },
  data() {
    return {
      chartStyles: {
        maxWidth: "100%",
        height: "100%",
        position: "relative",
      },
    };
  },
  computed: {
    ...mapState("data", ["currentFarm"]),
    currentTab: {
      set(val) {
        this.$emit("update:tab", val);
      },
      get() {
        return this.tab;
      },
    },
  },

  methods: {
    genChartData(property) {
      const labels = [];
      const max = {
        label: "max",
        data: [],
        yAxisID: "B",
        type: "line",
        borderColor: "#E91E63",
        backgroundColor: "transparent",
      };
      const mean = {
        label: "mean",
        data: [],
        type: "line",
        borderColor: "#00B8D4",
        backgroundColor: "transparent",
        yAxisID: "B",
      };
      const min = {
        label: "min",
        data: [],
        type: "line",
        borderColor: "#607D8B",
        backgroundColor: "transparent",
        yAxisID: "B",
      };
      const datasets = [min, max, mean];
      for (const report of this.currentFarm.reports) {
        labels.push(report.date);
      }

      for (const index of crop_property_ranges[property]) {
        let data = [];
        for (const report of this.currentFarm.reports) {
          data.push(report.metadata[property][index.name]);
          console.log(report.metadata[property][index.name]);
        }
        datasets.push({
          label: index.name,
          yAxisID: "A",
          data,
          backgroundColor: "#" + index.color,
        });
      }
      for (const report of this.currentFarm.reports) {
        max.data.push(report.metadata[property]["max"]);
        min.data.push(report.metadata[property]["min"]);
        mean.data.push(report.metadata[property]["mean"]);
      }

      return {
        labels,
        datasets,
      };
    },
  },
};
</script>
